.optin {
  margin-top: 8%;
}

.optin p {
  font-size: 14px;
  font-weight: 100;
}

.optin a {
  color: #ccc;

}
